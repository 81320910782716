
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getMyData } from '@/services/user.service'
import { getEventDetails } from '@/services/seller.service'
import { idoDateToDateAndHours } from '@/helpers/date'

@Component({
  components: {
    SellerHeader,
  },
})
export default class MyQR extends Vue {
  public user: any = ''
  event: any = ''
  qr_hash: string = ''
  $html2canvas: any

  idoDateToDateAndHours(date) {
    return idoDateToDateAndHours(date)
  }

  async getEventDetails() {
    const { data } = await getEventDetails(
      localStorage.getItem('selectedEvent'),
      false,
      true
    )
    this.event = data
  }

  async getMyData() {
    try {
      const { data } = await getMyData()
      this.user = data
      this.qr_hash = 'product-qr-' + data.uuid
    } catch (e) {
      console.log(e)
    }
  }

  capture() {
    const captureElement = document.querySelector('#capture') as HTMLElement

    this.$html2canvas(captureElement, {
      useCORS: true,
      background: captureElement.style.backgroundImage,
    }).then(canvas => {
      // Generar y descargar la imagen
      const image = canvas.toDataURL('image/png')
      const a = document.createElement('a')
      a.setAttribute('download', `TQR - ${this.user.fullname}.png`)
      a.setAttribute('href', image)
      a.click()
    })
  }

  //https://images.unsplash.com/photo-1545128485-c400e7702796?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80

  get generalStyles() {
    if (this.event?.config?.background_image) {
      return {
        'background-image': `url("${this.event?.config?.background_image}")`,
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        color: this.event?.config?.color + ' !important',
      }
    }
    return this.backgroundAndColor
  }

  get borders() {
    return {
      border: '2px solid ' + this.event?.config?.color + ' !important',
    }
  }

  get logo() {
    return this.event?.config?.logo
  }

  get backgroundAndColor() {
    return {
      'background-color': this.event?.config?.background_color || 'transparent',
      color: this.event?.config?.color + ' !important',
    }
  }

  async mounted() {
    await this.getMyData()
    await this.getEventDetails()
  }
}
